/* You can add global styles to this file, and also import other style files */
@import 'app/modules/advanced-search/components/filter-dialog/filter-dialog.style'; // you don't need the .scss suffix

@include myPanel();

.divider-underline {
  background-color: rgba(255, 255, 255, 0.7);
}

.custom-mat-label {
  color: rgba(255, 255, 255, 0.7);
}

.autompleteDropdown {
  max-height: 50vh !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

::-webkit-scrollbar {
  width: 18px;
}

::-webkit-scrollbar-track {
  background: #292c33;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  background: #22252b;
  border-radius: 0px;
}

tr.mat-row:nth-child(odd),
tr.mat-footer-row:nth-child(odd) {
  background: var(--background-color);
}

.mat-typography h3 {
  /* margin: -16px 0 16px!important; */
  margin: 0px 0 0px !important;
  color: #9ca6b3 !important;
}

.mat-title {
  margin: 0 !important;
}

textarea {
  line-height: 21px !important;
}

.quill {
  margin-bottom: 20px !important;
}

.sofco-theme-dark .quill {
  background-color: transparent !important;
  color: #d7dadf !important;
}

.sofco-theme-dark .ql-toolbar.ql-snow,
.sofco-theme-dark .ql-container.ql-snow {
  border-bottom: 0px solid #67686C !important;
  border-left: 0px solid #d7dadf !important;
  border-right: 0px solid #24272d !important;
  border-top: 0px solid #24272d !important;
}

.sofco-theme-dark .ql-snow .ql-stroke,
.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  stroke: #d7dadf !important;
}

.sofco-theme-dark .ql-editor.ql-blank::before {
  color: #9ca6b3 !important;
}

.mat-card-header-text {
  margin: 0 !important;
  width: 100%;
}

.sofco-theme-dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.globalHeaderCntr {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 25px;
}

.mat-simple-snack-bar-content {
  white-space: pre-wrap;
}

.mat-snack-bar-container {
  border-radius: 10px;

  &.error-snackbar {
    background: #f23a2f !important;
    --mat-snack-bar-button-color: #fff;
    color: #fff !important;
  }

  &.success-snackbar {
    --mdc-snackbar-container-color: #43a446;
  }

  &.transparent-snackbar {
    background: transparent !important;
    opacity: 1 !important;
    padding: 0px !important;
  }
}


.material-symbols-outlined-fill {
  font-variation-settings: 'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

.mat-select-search-input {
  background-color: var(--mat-select-panel-background-color) !important;
  caret-color: #ffffff !important;
}
