/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/
@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

//// Fonts
//@import 'https://fonts.googleapis.com/css?family=Material+Icons';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  //url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
  //url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
  url(https://kleksi.com/fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

$fontConfig: mat.define-legacy-typography-config(
  $font-family: 'Lato',
  $display-4: mat.define-typography-level(112px, 112px, 300,  'Lato' -0.0134em),
  $display-3: mat.define-typography-level(56px, 56px, 400, 'Lato'  -0.0089em),
  $display-2: mat.define-typography-level(45px, 48px, 400, 'Lato'  0em),
  $display-1: mat.define-typography-level(34px, 40px, 400, 'Lato'  0.0074em),
  $headline: mat.define-typography-level(24px, 32px, 400, 'Lato'  0em),
  $title: mat.define-typography-level(20px, 32px, 500,  'Lato' 0.0075em),
  $subheading-2: mat.define-typography-level(16px, 28px, 400,  'Lato' 0.0094em),
  $subheading-1: mat.define-typography-level(15px, 24px, 500,  'Lato' 0.0067em),
  $body-2: mat.define-typography-level(14px, 24px, 500,  'Lato' 0.0179em),
  $body-1: mat.define-typography-level(14px, 20px, 400,  'Lato' 0.0179em),
  $button: mat.define-typography-level(14px, 14px, 500,  'Lato' 0.0893em),
  $caption: mat.define-typography-level(12px, 20px, 400,  'Lato' 0.0333em),
  $input: mat.define-typography-level(inherit, 1.125, 400,  'Lato' 1.5px),
);

// Foreground Elements

// Light Theme Text
$dark-text: #292c33;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba(rgba(black, 0.87), 0.54);
$dark-disabled-text: rgba(rgba(black, 0.87), 0.38);
$dark-dividers: rgba(rgba(black, 0.87), 0.12);
$dark-focused: rgba(rgba(black, 0.87), 0.12);

mat.$light-theme-foreground-palette: (
  base: black,
  divider: rgba(black, 0.12),
  dividers: rgba(black, 0.12),
  disabled: rgba(black, 0.38),
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: rgba(black, 0.38),
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: rgba(black, 0.38),
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: rgba(black, 0.87),
  slider-min: rgba(black, 0.87),
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: rgba(black, 0.38)
);

// Dark Theme text
$light-text: #d7dadf;
$light-primary-text: $light-text;
$light-accent-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

mat.$dark-theme-foreground-palette: (
  base: $light-text,
  divider: rgba(white, 0.12),
  dividers: rgba(white, 0.12),
  disabled: rgba(white, 0.5),
  disabled-button: rgba($light-text, 0.3),
  disabled-text: rgba(white, 0.5),
  elevation: black,
  hint-text: rgba(white, 0.5),
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3)
);

// Background config
// Light bg
$light-background: #fafafa;
$light-border: #cccccc;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#292c33, 20%);
$dark-bg-alpha-4: rgba(#292c33, 0.04);
$dark-bg-alpha-12: rgba(#292c33, 0.12);

mat.$light-theme-background-palette: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: rgba(black, 0.12),
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
  border: $light-border
);

// Dark bg
$dark-background: #292c33;
$dark-border: darken($dark-background, 20%);
$dark-bg-darken-5: darken($dark-background, 2%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
mat.$dark-theme-background-palette: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-darken-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-darken-5,
  dialog: $dark-bg-darken-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-darken-5,
  focused-button: rgba(white, 0.12),
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
  border: $dark-border
);

// Compute font config
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
@include mat.core();

// Theme Config

//body {
//  --primary-color: #292C33;
//  --primary-lighter-color: #51555c;
//  --primary-darker-color: #00000c;
//  --text-primary-color: #{$light-primary-text};
//  --text-primary-lighter-color: #{$light-primary-text};
//  --text-primary-darker-color: #{$light-primary-text};
//}

$mat-primary: (
  main: #292c33,
  lighter: #51555c,
  darker: #00000c,
  200: #292c33,
  // For slide toggle,
  contrast:
    (
      main: white,
      lighter: white,
      darker: white,
    ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

//body {
//  --accent-color: #9CA6B3;
//  --accent-lighter-color: #b3e4f6;
//  --accent-darker-color: #008bd6;
//  --text-accent-color: #{$light-primary-text};
//  --text-accent-lighter-color: #{$dark-primary-text};
//  --text-accent-darker-color: #{$light-primary-text};
//}

$mat-accent: (
  main: #9ca6b3,
  lighter: #b3e4f6,
  darker: #008bd6,
  200: #9ca6b3,
  // For slide toggle,
  contrast:
    (
      main: #292c33,
      lighter: rgba(black, 0.87),
      darker: white,
    ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

//body {
//  --warn-color: #e2272a;
//  --warn-lighter-color: #f6bebf;
//  --warn-darker-color: #d61719;
//  --text-warn-color: #{$light-primary-text};
//  --text-warn-lighter-color: #{$dark-primary-text};
//  --text-warn-darker-color: #{$light-primary-text};
//}




$mat-warn: (
  main: #e2272a,
  lighter: #f6bebf,
  darker: #d61719,
  200: #e2272a,
  // For slide toggle,
  contrast:
    (
      main: white,
      lighter: rgba(black, 0.87),
      darker: white,
    ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
$oldTheme: mat.define-dark-theme($theme-primary, $theme-accent, $theme-warn);
$theme: mat.define-dark-theme(
    (
      color: (
        primary: $theme-primary,
        accent: $theme-accent,
        warn: $theme-warn
      ),
      typography: $fontConfig
    )
);
$altTheme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);

@import 'functions';

:root{
  @include mat.core-theme($theme);
  //@include mat.button-theme(custom-theme.$light-theme);
  //@include mat.all-component-themes($theme);
  @include mat.dialog-theme($theme);
  //@include mat.color-variants-backwards-compatibility($theme);
}

.sofco-theme-dark {
  // Theme Init
  @include mat.all-legacy-component-themes($theme);
  @include mat.all-component-themes($theme);
  @include generate-theme-vars($theme);
  //
  @include mtx.all-component-themes($theme);
  //@include mat.typography-level($typographyConfig);
  //@include mtx.all-experimental-component-themes($theme);
}

.sofco-theme-light {
  @include mat.all-legacy-component-themes($altTheme);
  @include mat.all-component-themes($altTheme);
  @include generate-theme-vars($altTheme);
  //
  //@include mtx.all-component-themes($theme);
  //@include mtx.all-experimental-component-themes($theme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 22px;
  font-family: 'Material Icons', 'Material Icons';
  .mat-badge-content {
    font-family: 'Roboto';
  }
}

.mat-icon-button.helpButtons {
  width: 28px;
}

.mat-expansion-panel-header {
  color: #9EA5B2;
}
.mat-expansion-panel {
  background: #24272C;
  color: #24272C;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #5f49f3!important;
}
