@mixin myPanel() {
  .filter-dialog .mat-dialog-container {
    // css here
    background: black !important;
    padding: 0px !important;
    overflow: hidden !important;
    min-height: 230px !important;

    .mat-dialog-title{
      border-bottom: 1px solid var(--divider-color);
      padding: 15px;
      margin: 0px !important;
    }


    .dialogContent{
      padding: 15px;
    }

    .mat-dialog-actions{
      border-top: 1px solid var(--divider-color);
      padding: 15px;
    }
  }
}
